<template>
	<header v-if="isLoggedIn" class="topHead container-fluid d-print-none">
		<div class="row">
			<div class="col-sm-9 col-xl-4 d-flex" style="padding-left:60px;">
				<router-link to="/">
					<img src="../assets/image/topHeadLogoLeft.png" class="img-fluid" style="position:absolute; width:32%" />
					<img src="../assets/image/topHeadLogo2.png" class="img-fluid align-self-center pt-4 pb-3" />
				</router-link>
			</div>
			<div class="py-3 col-sm-3 col-xl-8 d-flex align-items-center justify-content-end order-first order-sm-last headNav">
				<app-nav />
			</div>
		</div>

		<div class="headBg pt-5">
			<div class="container">
				<div class="row no-gutters">
					<div class="col-9 col-xl-8  my-5 my-lg-5">
						<div class="text-lg-left text-center headName">Hello {{ firstname }}</div>
						<div class="text-lg-left text-center headWelcome">WELCOME BACK</div>
					</div>

					<div class="col-3 col-xl-4 my-1 my-lg-2 text-left text-lg-left">
						<router-link to="/myprofile" title="Change profile picture or update details">
							<img id="imgProfilePic" :src="profilePicSrc" class="headprofilepic" />
						</router-link>
					</div>
				</div>
			</div>
		</div>

		<div class="container headPoints">
			<div class="row">
				<div class="col-6 col-xl-3 headPointsRedeem">{{ $store.state.memberStore.member.awardsPointsAvailable + ' ' + $store.state.memberStore.member.pointsName }}</div>
				<div v-if="$store.state.memberStore.member.isManager" class="col-6 col-xl-3 headPointsRecognise">{{ $store.state.memberStore.member.awardsPointsAvailableMng + ' ' + $store.state.memberStore.member.pointsName }}</div>
			</div>
			<div class="row">
				<div class="col-6 col-xl-3 headPointsSuffix">TO REDEEM</div>
				<div v-if="$store.state.memberStore.member.isManager" class="col-6 col-xl-3 headPointsSuffix">TO RECOGNISE</div>
			</div>
		</div>

	</header>
	<header v-else class="topHead container-fluid d-print-none pt-3 pb-3">
		<div class="row">
			<div class="col-sm-9 col-xl-4 d-flex" style="padding-left:60px;">
				<img src="../assets/image/topHeadLogoLeft.png" class="img-fluid" style="position:absolute; width:32%" />
				<img src="../assets/image/topHeadLogo2.png" class="img-fluid align-self-center pt-4 pb-3" />
			</div>
			<div class="col-sm-3 col-xl-8 d-flex align-items-center justify-content-end order-first order-sm-last ">
				<app-nav />
			</div>
		</div>
	</header>
</template>

<script>
	import AppNav from '@/components/AppNav'
	//
	//import { mapState } from 'vuex'
	//import SitecodeImage from '@/components/SitecodeImage';
	export default {
		name: 'AppHeader',
		components: {
			//'sitecode-image': SitecodeImage
			AppNav
		},
		computed: {
			windowWidth() {
				return this.$store.state.windowWidth
			},
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
			rootFolder() {
				return process.env.BASE_URL
			},
			//        imgUrl() {
			////return '../assets/image/topHeadLogo_' + this.$store.state.siteTemplate + '.png';
			//return require('../assets/image/topHeadLogo_' + this.$store.state.siteTemplate + '.png');
			//        },
			firstname() {
				return this.$store.state.memberStore.member.firstname
			},//,
			profilePicSrc() {
				return '/profilepic/' + this.$store.state.memberStore.member.picId + this.$store.state.memberStore.profilepicSfx
			},//,
			//...mapState({
			//    member: state => state.memberStore.member
			//})
			awardsPointsAvailable() {
				return this.$store.state.memberStore.member.awardsPointsAvailable + ' ' + this.$store.state.memberStore.member.pointsName;
			},
			awardsPointsAvailable1() {
				return this.$store.state.memberStore.member.awardsPointsAvailable1 + ' ' + this.$store.state.memberStore.member.pointsName;
			},
			awardsPointsAvailableMng() {
				return this.$store.state.memberStore.member.awardsPointsAvailableMng + ' ' + this.$store.state.memberStore.member.pointsName;
			}

		},
		mounted() {
			this.$nextTick(() => {
				this.handleResize();
			});
		},
		updated() {
			this.$nextTick(() => {
				this.handleResize();
			});
		},
		watch: {
			windowWidth() { //newWidth, oldWidth
				//console.log(`width changed to ${newWidth} from ${oldWidth}`);
				this.handleResize();
			}
		},
		methods: {
			handleResize: function () {
				if (this.$store.state.siteTemplate != 'santam') return; //Only Santam needs sizing
				if (!this.$refs.topHeadLogoLeft) {
					console.log('handleResize: cannot find topHeadLogoLeft');
					setTimeout(this.handleResize, 250)
					return;
				}

				let h = this.$refs.topHeadLogoLeft.firstElementChild.offsetHeight; //$('.homeicon1').innerHeight();
				let w = this.$refs.topHeadLogoLeft.firstElementChild.offsetWidth; //$('.homeicon1').innerWidth();

				if (w < 3 || h < 3) {
					//console.log('handleResize: topHeadLogoLeft w or h = 0');
					setTimeout(this.handleResize, 250)
					return;
				}

				let sw = w / 354;
				let sh = h / 298;

				console.log('handleResize: topHeadLogoLeft w: ' + sw + ', h: ' + sh);
				this.$refs.imgProfilePic.style.left = (sw * 122) + "px";
				this.$refs.imgProfilePic.style.top = (sh * 68) + "px";
				this.$refs.imgProfilePic.style.width = (sw * 222) + "px";
				this.$refs.imgProfilePic.style.height = (sh * 222) + "px";
			}
		}
	}
</script>

