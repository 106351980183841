<template>
	<div class="picbox" :style="{ backgroundColor: '#' + pic.campaignColour }">
		<div class="namebox"><b>{{ pic.firstName.toUpperCase() }}</b><br />{{ pic.surname.toUpperCase() }}</div>
		<div><img alt="" :src="picSrc" /></div>
		<div class="picdate">{{ pic.dateCreated.sjToDate('d MMM yyyy HH:mm') }}</div>
		<button v-if="mode=='myprofile'" class="btn btn-sm btn-secondary" type="button" @click="deletePic()">DELETE</button>

		<div v-if="mode=='campaign'">
			<div class="row">
				<div class="col-sm-6">
					<button v-if="pic.canVote" class="btn btn-sm btn-secondary" type="button" @click="likePic"><b-icon icon="hand-thumbs-up" aria-hidden="true" class="mr-2"></b-icon>LIKE</button>
					<button v-if="!pic.canVote" class="btn btn-sm btn-secondary" disabled type="button" title="You have already LIKED this picture. (Or its your own!)">LIKED!</button>
				</div>
				<div class="col-sm-6 text-sm-right d-flex align-items-center text-right">
					TOTAL: <span class="ml-2">{{ pic.totalLikes }}</span>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	//import axios from 'axios'
	import axios from 'axios'
	//import sjData from '@/sjUtilData'

	export default {
		name: 'getInvolvedPic',
		props: {
			//option 1 = (campaign selection) --NOT USED HERE
			//Option 2 = Current/Prev Theme
			//Option 3 = Member's Pics
			//Option 4 = Profile Pics
			//	1 = Theme Selection - Shows only picBox. (No namebox, actionbox, linkbox).  Picture link to show theme
			//	2 = Current Theme   - Shows namebox, actionbox with Like button, linkbox. Picture link to person's pics.
			//	3 = Member's Pics   - Shows namebox, actionbox with Like button.  (No linkbox, and no picture link).
			//	4 = Profile Pics   -  Shows namebox, actionbox with Delete button. (No linkbox, and no picture link).
			//	5 = Upload Pic     -  Shows namebox only
			pic: Object,
			mode: String,
			/*
			option: String,
			themeTitle: String,
			themeName: String,
			themeColour: String,

			picId: Number,
			firstname: String,
			surname: String,
			likeCount: Number,
			canVote: Boolean,
			picdate: String,
			*/
		},
		data() {
			return {
				campaigns: [],
				campaignIdx: null,
				isLoading: false,
				filePic: null,
				picSfx: '0',
				angle: 0,
				savePicTermsAccepted: false,
			}
		},
		computed: {
			//member() {
			//	return this.$store.state.memberStore.member
			//},

			//isLoggedIn() {
			//	return this.$store.state.isLoggedIn
			//},

			picSrc() {
				return '/getinvolvedpic/' + this.pic.campaignId + '-' + this.pic.getInvolvedId;
			},



		},

		mounted() {
		},
		watch: {

		},
		methods: {
			likePic() {
				this.isLoading = true;
				axios.post('/api/getinvolved/likepic', { campaignId: this.pic.campaignId, giId: this.pic.getInvolvedId })
					.then(({ data }) => {
						this.isLoading = false;
						let pic = this.pic;
						console.log('$emit like-update ' + pic.getInvolvedId + " likes: " + data.totalLikes)
						this.$emit('like-update', { getInvolvedId: pic.getInvolvedId, likes: data.totalLikes });
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error);
						this.msgBox(error.response.data.message, "An error has occurred");
					})
			},
			deletePic() {
				this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this uploaded picture.', {
					title: 'Please Confirm',
					size: 'sm',
					buttonSize: 'md',
					okTitle: 'Yes',
					cancelTitle: 'No',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true
				})
					.then(value => {
						if (!value) return;
						this.isLoading = true;
						axios.post('/api/getinvolved/deletepic', { campaignId: this.pic.campaignId, giId: this.pic.getInvolvedId })
							.then(() => {
								this.isLoading = false;
								this.$emit('delete-pic', this.pic.getInvolvedId);
							})
							.catch((error) => {
								this.isLoading = false;
								console.log(error);
								this.msgBox(error.response.data.message, "An error has occurred");
							});
					});

			},
			msgBox(msg, title) {
				this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
			},

		}
	}
</script>

