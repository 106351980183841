<template v-if="isLoggedIn && isAllowAccess">
    <div class="container-fluid page">

        <div class="card card-default" style="min-width:420px">
            <div class="card-header">
                <div class="row">
                    <div class="col-sm-6 font-weight-bold">
                        <h5>Management Report</h5>
                    </div>

                    <div class="col-sm-3">

                    </div>
                    <div class="col-sm-3 text-right">
                        <b-button class="d-print-none mr-2" size="sm" @click.prevent="windowPrint" :disabled="report.length==0">Print</b-button>
                        <b-button class="d-print-none" size="sm" @click.prevent="exportToCSV" :disabled="report.length==0">Export to CSV</b-button>
                    </div>
                </div>

            </div>
            <div class="card-body">
                <div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-md-2 control-label">Date:</label>
                        <div class="col-sm-7 col-md-5 col-lg-3">
                            <b-input-group size="sm">
                                <b-input-group-prepend is-text>
                                    <b-icon icon="calendar"></b-icon>
                                </b-input-group-prepend>
                                <b-form-select :options="monthList" v-model="selMonth">
                                    <!--<template v-slot:first>
                                        <b-form-select-option :value="null">-- All Periods --</b-form-select-option>
                                    </template>-->
                                </b-form-select>
                            </b-input-group>
                        </div>
                    </div>
                    <div class="form-group row" v-if="isAdminReports">
                        <label class="col-sm-3 col-md-2 control-label">Division:</label>
                        <div class="col-sm-7 col-md-7">
                            <b-form-radio-group id="selClusterGrp" v-model="isAll">
                                <b-form-radio :value="false">Current</b-form-radio>
                                <b-form-radio :value="true">All Divisions</b-form-radio>
                            </b-form-radio-group>
                        </div>
                        <div class="col-sm-2 col-md-3">

                        </div>
                    </div>
                </div>

            </div>
        </div>

        <table class="table table-hover table-condensed" style="font-size:0.8rem">
            <tr><th>Type</th><th>Date</th><th>From</th><th>Division</th><th>To</th><th>Division</th><th class="text-right">Points</th><th>Value</th><th>Message</th></tr>

            <tr v-for="f in report" :key="f._rowNum">
                <td>{{ f.type }}</td>
                <td>{{ f.date.sjToDate('d MMM yyyy HH:mm') }}</td>
                <td>{{ `${f.from} (${f.fromMemberNo})`}}</td>
                <td>{{ f.fromDivision }}</td>
                <td>{{ `${f.to} (${f.toMemberNo})`}}</td>
                <td>{{ f.toDivision }}</td>
                <td class="text-right">{{ f.points != 0 ? f.points : '' }} </td>
                <td>{{ f.value }}</td>
                <td class="preline">{{ f.message }}</td>
            </tr>
        </table>
        <div v-if="isLoading"><i>Loading...</i></div>
        <div v-if="!isLoading && report.length==0"><i>No Results</i></div>

    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'


    export default {
        name: 'ReportManagement',
        data() {
            return {
                monthList: [],
                selMonth: null,
                isAll: false,
                report: [],
                isLoading: false
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },

            isAllowAccess() {
                let m = this.$store.state.memberStore.member;
                return m.isManager;
            },

            isAdminReports() {
                let m = this.$store.state.memberStore.member;
                return m.isAdminReports;
            },
        },

        created() {
            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const result = [];
            let curYear = new Date().getFullYear();
            let curMonth = new Date().getMonth();

            for (let y = curYear; y >= curYear - 3; y--) {
                let mStart = y == curYear ? curMonth : 11;
                for (let m = mStart; m >= 0; m--) {
                    let t = months[m] + ' ' + y;
                    if (result.length === 0) this.selMonth = t;
                    result.push({ value: t, text: t });
                }
            }
            this.monthList = result;
            this.loadReport();
        },
        mounted() {
            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
        watch: {
            selMonth: function () {
                if (this.selMonth !== null) {
                    this.loadReport();
                }
            },
            isAll: function () {
                this.loadReport();
            }
        },

        methods: {
            loadReport() {
                this.isLoading = true;
                this.report = [];
                axios.post('/api/report/reportmanagement', { selMonth: this.selMonth, isAll: this.isAll })
                    .then(({ data }) => {
                        this.isLoading = false;

                        this.report = new sjData(data.report).data;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },
            exportToCSV() {
                let fx = function (val, fn) {
                    if (typeof fn === "undefined") fn = '';
                    let isNum = (fn === 'num' || typeof val === "number") && !isNaN(val);
                    try {
                        if (isNum) {
                            return val;
                        } else {
                            let s = val.sjReplaceAll("\r\n", " ~ ")
                                .sjReplaceAll("\r", " ~ ")
                                .sjReplaceAll("\n", " ~ ")
                                .sjReplaceAll("\t", "   ")
                                .trim();

                            //ph - phone number - split after 3 chars
                            if (fn == "ph" && s.length > 9) {
                                if (s.substr(0, 1) === "0") {
                                    s = s.sjReplaceAll(' ', '').sjReplaceAll('-', '').sjReplaceAll('(', '').sjReplaceAll(')', '').substr(0, 3) + " " + s.substr(3, 3) + " " + s.substr(6, 4);
                                } else {
                                    s = "'" + s;
                                }
                            }

                            //https://blog.zsec.uk/csv-dangers-mitigations/
                            if (s.indexOf("@") === 1 || s.indexOf("+") === 1 || s.indexOf("-") === 1 || s.indexOf("=") === 1 || s.indexOf("|") === 1 || s.indexOf("%") === 1) {
                                s = "'" + s.sjReplaceAll("|", "\\|");
                            }

                            s = "\"" + s.sjReplaceAll("\"", "\"\"") + "\"";
                            return s;
                        }
                    } catch {
                        return 'Error';
                    }
                }

                let csv = 'Type,Date,From,Member No,Division,To,Member No, Division,Points,Value,Message\r\n';

                for (var n = 0; n < this.report.length; n++) {
                    let f = this.report[n];
                    //debugger;
                    csv += fx(f.type) + ','
                        + fx(f.date.sjToDate('d MMM yyyy HH:mm')) + ','
                        + fx(f.from) + ','
                        + fx(f.fromMemberNo) + ','
                        + fx(f.fromDivision) + ','
                        + fx(f.to) + ','
                        + fx(f.toMemberNo) + ','
                        + fx(f.toDivision) + ','
                        + fx(f.points != 0 ? f.points : '') + ','
                        + fx(f.value) + ','
                        + fx(f.message) + '\r\n';
                }

                var pom = document.createElement('a');
                pom.href = URL.createObjectURL(new Blob(['\uFEFF', csv], { type: 'text/csv;charset=utf-8;' }));
                pom.setAttribute('download', 'report.csv');
                pom.click();
            },
            windowPrint() {
                window.print();
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            }


        }

    }
</script>
