<template>
    <footer class="pagefooter d-print-none mt-5">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-3 d-flex align-items-center justify-content-center">
                    <div><img src="../assets/image/footLogo.png" class="img-fluid" /></div>
                </div>
                <div class="col-sm-6 text-center d-flex align-items-center justify-content-center">
                    <div>
                        <div>
                            <template v-if="isLoggedIn">
                                <b><a href="#" @click.prevent="$bvModal.show('modalHowItWorks')">How it Works</a></b> |
                            </template>
                            <b><a href="#" @click.prevent="$bvModal.show('modalCookiePolicyPreferences')">Cookie Preferences</a></b> |
                            <b><a href="#" @click.prevent="$bvModal.show('modalPrivacy')">Privacy Policy</a></b> |
                            <b><a href="#" @click.prevent="$bvModal.show('modalDisclaimer')">Disclaimer</a></b>
                        </div>
                        <div>
                            Copyright &copy; {{ (new Date()).getFullYear() }} Avon Justine / Watch us win
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 text-right d-flex align-items-center justify-content-center">
                    <div><img src="../assets/image/footPoweredBy.png" class="img-fluid" /></div>
                </div>
            </div>
        </div>
        <!--<img src="../assets/image/footer.png" class="w-100 d-none d-lg-block" />
        <img src="../assets/image/footerMobi.png" class="w-100 d-lg-none" />-->
    </footer>



</template>

<script>
//import { mapState } from 'vuex'
    export default {
        name: 'AppFooter',
        components: {
        },
        computed: {
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
            rootFolder() {
                return process.env.BASE_URL
            }
        }
    }
</script>

