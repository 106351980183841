<template>
    <div v-if="isLoggedIn" class="container page">
        <div class="row">
            <div class="col-sm-6 font-weight-bold">
                <h3>Nominations Management</h3>
            </div>

            <div class="col-sm-3">

            </div>
            <div class="col-sm-3 text-right">
                <b-button class="d-print-none mr-2" size="sm" @click.prevent="windowPrint">Print</b-button>
                <b-button class="d-print-none" size="sm" @click.prevent="exportToCSV" :disabled="nomsFiltered.length==0">Export to CSV</b-button>
            </div>
        </div>



        <content-db v-if="isAdminReports" contentKey="topmsgnominationmanagement" :isCache="true"></content-db>
        <hr />
        <b-alert v-if="!isAllowAccess" show>Only authoirised personel may access this module.</b-alert>

        <template v-if="isAllowAccess && displayPart==0">
            Please wait, loading nominations
        </template>

        <template v-if="displayPart==1">


            <div style="padding-right: 30px;  border-bottom: solid 2px #eee ">
                <div class="row mb-1">
                    <div class="col-lg-3 font-weight-bold">
                        <b-input-group size="sm">
                            <b-input-group-prepend is-text>
                                <b-icon icon="award"></b-icon>
                            </b-input-group-prepend>
                            <b-form-select :options="nomCats" v-model="filterNomCat" :disabled="filterShowSel">
                                <template v-slot:first>
                                    <b-form-select-option :value="null">-- All Values --</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-input-group>
                    </div>
                    <div class="col-lg-3 font-weight-bold">
                        <b-input-group size="sm">
                            <b-input-group-prepend is-text>
                                <b-icon icon="award"></b-icon>
                            </b-input-group-prepend>
                            <b-form-select :options="nomineeDivisions" v-model="filterNomineeDivision" :disabled="filterShowSel">
                                <template v-slot:first>
                                    <b-form-select-option :value="null">-- All Divisions --</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-input-group>
                    </div>
                    <div class="col-lg-3 font-weight-bold text-lg-center">
                        <b-input-group size="sm">
                            <b-input-group-prepend is-text>
                                <b-icon icon="calendar"></b-icon>
                            </b-input-group-prepend>
                            <b-form-select :options="nomMonths" v-model="filterMonth" :disabled="filterShowSel">
                                <template v-slot:first>
                                    <b-form-select-option :value="null">-- All Months --</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-input-group>
                    </div>
                    <div class="col-lg-3">
                        <b-input-group size="sm" v-if="roles.cc || roles.ca">
                            <b-input-group-prepend is-text>
                                <b-icon icon="card-checklist"></b-icon>
                            </b-input-group-prepend>
                            <b-form-select v-model="filterStatus" :disabled="filterShowSel">
                                <b-form-select-option :value="null">-- All Statuses --</b-form-select-option>
                                <b-form-select-option value="review">To Review</b-form-select-option>
                                <b-form-select-option value="rated">Ratings Submitted</b-form-select-option>
                                <b-form-select-option :value="null" disabled>------------------</b-form-select-option>
                                <b-form-select-option value="rate">You have not Rated</b-form-select-option>
                            </b-form-select>
                        </b-input-group>
                    </div>
                </div>
            </div>

            <div>
                <div v-for="n in nomsFiltered" :key="n._rowNum">

                    <section class="card card-default mt-3" v-if="n.nomId != ''">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-5">
                                    <b>{{ n.nomineeMember + ' (' + n.nomineeMemberNumber + ')' }}</b>
                                    <div style="font-size:smaller">Manager: {{ n.nomineeManagerMember + ' (' + n.nomineeManagerMemberNumber + ')' }}</div>
                                </div>
                                <div class="col-3">{{ n.nomineeDivision }}</div>
                                <div class="col-4 text-right"><strong></strong> #{{ n.nomId.sjRemoveIntHash() }} <span class="pl-5"> {{ n.dateCreated.sjToDate('d MMM yyyy HH:mm') }}</span></div>
                                <!--<b-button v-b-toggle="'collapse-' + n._rowNum" variant="primary">Toggle Collapse</b-button>-->
                            </div>
                        </div>

                        <div class="card-body">
                            <!-- Nominee List -->

                            <div class="row mb-2">
                                <div class="col-lg-2 fldNameB">Nominated for:</div>
                                <div class="col-lg-4 fldValueB">{{ n.nomCat }}</div>
                                <div class="col-lg-2 fldNameB">Nominated by:</div>
                                <div class="col-lg-4 fldValueB">{{ n.byMember + ' (' + n.byMemberNumber + ') - ' + n.byDivision }}</div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-lg-2 fldNameB">Reason:</div>
                                <div class="col-lg-8 fldValueB">{{ n.reason }}</div>
                                <div class="col-lg-2 text-right d-flex align-items-end justify-content-end"><b-button type="button" pill @click="selectNom(n._rowNum)" variant="primary" size="sm" class="d-print-none">Review</b-button></div>
                            </div>

                        </div>
                    </section>
                </div>
                <div v-if="nomsFiltered.length===0"><i>(No nominations found)</i></div>
            </div>

        </template>

        <!--
    **************************************************************************************************************************************************
                                                                M O D A L S
    **************************************************************************************************************************************************
    -->
        <!-- Modal - Nominees -->
        <b-modal id="modalReview" title="REVIEW NOMINATION" hide-footer size="xl" no-close-on-backdrop>
            <section class="card card-default mt-3">
                <div class="card-header">
                    <div class="row">
                        <div class="col-5 font-weight-bold">{{ nom.nominee }}</div>
                        <div class="col-3">{{ nom.nomineeDivision }}</div>
                        <div class="col-4 text-right"><strong></strong> #{{ nom.nomId.sjRemoveIntHash() }} <span class="pl-5"> {{ nom.dateCreated.sjToDate('d MMM yyyy HH:mm') }}</span></div>
                        <!--<b-button v-b-toggle="'collapse-' + n._rowNum" variant="primary">Toggle Collapse</b-button>-->
                    </div>
                </div>

                <div class="card-body">
                    <!-- Nominee List -->

                    <div class="row mb-2">
                        <div class="col-lg-2 fldNameB">Nominated for:</div>
                        <div class="col-lg-4 fldValueB">{{ nom.nomCat }}</div>
                        <div class="col-lg-2 fldNameB">Nominated by:</div>
                        <div class="col-lg-4 fldValueB">{{ nom.nominatedBy }}</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-2 fldNameB">Reason:</div>
                        <div class="col-lg-8 fldValueB">{{ nom.reason }}</div>
                        <!--<div class="col-lg-2 text-right d-flex align-items-end justify-content-end"><b-button type="button" pill @click="$bvModal.show('modalReview')" variant="primary" size="sm">Review</b-button></div>-->
                    </div>
                </div>

            </section>

            <hr />

            <!-- Review Form -->
            <section class="card card-default mt-3">
                <div class="card-body">
                    <b-form @submit.prevent="reviewSubmit" novalidate>
                        <div class="row">
                            <b-form-group class="col-lg-6" id="reviewRating_Group" label-for="reviewRating_Form" label="Please provide a rating 1-5 for this nomination:" :invalid-feedback="fReview.errors.reviewRating" :state="fReview.state.reviewRating" description="Select number of stars to provide a rating out of 5">
                                <b-input-group>
                                    <b-form-rating name="reviewRating_Form" v-model="fReview.form.reviewRating" color="#00b" stars="5" :state="fReview.state.reviewRating"></b-form-rating>
                                    <b-input-group-append>
                                        <b-input-group-text class="justify-content-center" style="min-width: 3em;">
                                            <span class="mr-2"></span><b>{{ reviewPointsValue }} </b>
                                        </b-input-group-text>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </div>

                        <b-form-group v-if="fReview.form.reviewRating > 3" id="isIncludeInQuarterly_Group" label-for="isIncludeInQuarterly_Radio" label="Include in quarterly award?" description="" :invalid-feedback="fReview.errors.isIncludeInQuarterly" :state="fReview.state.isIncludeInQuarterly">
                            <b-form-radio-group id="isIncludeInQuarterly_Group" v-model="fReview.form.isIncludeInQuarterly" name="isIncludeInQuarterly_Radio" :state="fReview.state.isIncludeInQuarterly">
                                <b-form-radio :value="false">No</b-form-radio>
                                <b-form-radio :value="true">Yes</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>

                        <div v-else>At least 4 stars are required for the option to be included in the Quarterly Nominations.</div>


                        <b-form-group v-show="fReview.form.reviewRating > 3 && fReview.form.isIncludeInQuarterly" id="quarterlyMotivation_Group" label-for="quarterlyMotivation_Text" label="If Yes, Please provide details:" :invalid-feedback="fReview.errors.quarterlyMotivation" :description="500-fReview.form.quarterlyMotivation.length + ' characters remaining'">
                            <b-form-textarea id="quarterlyMotivation_Text" v-model="fReview.form.quarterlyMotivation" :state="fReview.state.quarterlyMotivation" placeholder="" rows="3" maxlength="500" />
                        </b-form-group>

                        <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                    </b-form>
                </div>
            </section>

            <!--<div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseNominees')">Done</b-button></div>-->
        </b-modal>

    </div>


</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';
    import axios from 'axios'
    import sjData from '@/sjUtilData'
    import ContentDb from '@/components/ContentDb';

    export default {
        name: 'NominationManagement',
        components: { ContentDb },
        data() {
            return {
                isPageReadyDone: false,
                displayPart: 0,
                noms: [],
                nom: { nomId: '', dateCreated: '' },
                fReview: {
                    form: { reviewRating: null, isIncludeInQuarterly: null, quarterlyMotivation: '' }
                },
                roles: { mng: false },

                filterNomCat: null,
                filterNomineeDivision: null,
                filterStatus: null,
                filterMonth: null,
                filterShowSel: false,



            }
        },
        computed: {
            isPageReady() {
                //console.log('isPageReady: isLoggedIn:' + this.$store.state.isLoggedIn + ' isDivisionsLoaded:' + this.$store.state.isDivisionsLoaded + ' isMemberLoaded:' + this.$store.state.memberStore.isMemberLoaded);
                return this.$store.state.isLoggedIn && this.$store.state.isDivisionsLoaded && this.$store.state.memberStore.isMemberLoaded;
            },
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
            isAllowAccess() {
                let m = this.$store.state.memberStore.member;
                return m.isManager;
            },
            isAdminReports() {
                let m = this.$store.state.memberStore.member;
                return m.isAdminReports;
            },
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
            nomCats() {
                return [...new Set(this.noms.map(n => n.nomCat))].sort()
            },
            nomineeDivisions() {
                return [...new Set(this.noms.map(n => n.nomineeDivision))].sort()
            },

            reviewPointsValue() {
                /*
                 *
1 Stars = Thank you eCard
2 Stars =R50
3 Stars =R100
4 Stars=R150
5 Stars =R200
6 Stars =R250
7 Stars= R300
*/
                const pointsName = this.$store.state.memberStore.member.pointsName;
                const status = ['Thank you eCard', 'Thank you eCard', 'Reward: 50 ' + pointsName, 'Reward: 100 ' + pointsName, 'Reward: 150 ' + pointsName, 'Reward: 200 ' + pointsName];
                let r = this.fReview.form.reviewRating;
                return status[r];
            },

            nomMonths() {
                const result = [];
                const map = new Map();
                for (const n of this.noms) {
                    let d = new Date(n.dateCreated + 'Z');
                    let v = { value: { m: d.getMonth(), y: d.getFullYear(), d }, text: d.sjFormat('MMMM yyyy') };
                    let z = v.value.y * 1000 + v.value.m;
                    if (!map.has(z)) {
                        map.set(z, true);    // set any value to Map
                        result.push(v);
                    }
                }
                return result.sort((a, b) => ((1000 * a.value.y + a.value.m) - (1000 * b.value.y + b.value.m)));
            },

            nomsFiltered() {
                let fltdNoms = this.noms;
                let nomsSel = this.nomsSelected;
                if (this.filterShowSel) {
                    return fltdNoms.filter(n => nomsSel.find(e => e == n.nomId));
                }

                var fltrStatus = this.filterStatus;
                var fltrMonth = this.filterMonth;
                if (this.filterNomCat !== null) fltdNoms = fltdNoms.filter(n => n.nomCat == this.filterNomCat);
                if (this.filterNomineeDivision !== null) fltdNoms = fltdNoms.filter(n => n.nomineeDivision == this.filterNomineeDivision);
                if (fltrStatus !== null) fltdNoms = fltdNoms.filter(n =>
                    (fltrStatus === 'review' && !n.reviewIsDone)
                    || (fltrStatus === 'rate' && n.reviewIsDone && !n.ratingIsDone)
                    || (fltrStatus === 'rated' && n.reviewIsDone && n.ratingCount != 0)
                ); //review, rate, rated
                if (fltrMonth !== null) fltdNoms = fltdNoms.filter(n => {
                    let d = new Date(n.dateCreated + 'Z');
                    return d.getMonth() === fltrMonth.m && d.getFullYear() === fltrMonth.y;
                });

                return fltdNoms;
            }

        },
        created() {
            this.formSetState(this.fReview, true, true) //formObj, resetState, resetForm
            this.$store
                .dispatch('getDivisionList', {})
                .then(() => {
                    this.onPageReady();
                })
                .catch((error) => {
                    this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error)));
                });
        },
        mounted() {
        },
        watch: {
            isPageReady() { //newWidth, oldWidth
                this.onPageReady();
            }
        },
        methods: {
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
            resetAll() {
                this.nomsSelected = [];
                this.nom = { nomId: '', dateCreated: '' };
                this.nominees = [];
                this.nomNominees = [];
                this.nomRatings = [];
            },

            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj === 'undefined') {
                    console.log('SJError in formSetState() - formObj is undefined');
                    debugger;
                    return;
                }

                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },

            onPageReady() {
                //console.log('onPageReady called');
                if (!this.isPageReady) {
                    console.log(' -> Page not ready');
                    return;
                }

                if (this.isPageReadyDone) return;
                this.isPageReadyDone = true;

                console.log('onPageReady Process!');
                console.log('onPageReady: isManager:' + this.$store.state.memberStore.member.isManager);


                if (!this.isAllowAccess) {
                    this.msgBox('Only Managers have access to this module');
                    return;
                }
                axios.post('/api/nom/nomlist', {})
                    .then(({ data }) => {
                        this.roles = data.roles;
                        this.noms = new sjData(data.noms).data;
                        this.displayPart = 1;
                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox('An unexpected error occurred');
                            //this.msgBox(error.response.data.message);
                        }
                    });
            },

            selectNom(rowNum) {
                axios.post('/api/nom/nomget', { nomId: this.noms[rowNum].nomId })
                    .then(({ data }) => {
                        this.selectNom_Loaded(data);
                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },
            selectNom_Loaded(data) {
                this.formSetState(this.fReview, true, true);//formObj, resetState, resetForm
                this.nom = new sjData(data.nom).data[0];
                this.$bvModal.show('modalReview');
            },

            reviewSubmit() {
                this.formSetState(this.fReview, true);//formObj, resetState, resetForm

                this.fReview.form.nomId = this.nom.nomId;

                if (this.fReview.form.reviewRating <= 3) {
                    this.fReview.form.isIncludeInQuarterly = false;
                    this.fReview.form.quarterlyMotivation = "";
                }

                return axios
                    .post(
                        '/api/reward/doavonnomreviewreward', //***NB in Reward controller
                        this.fReview.form
                    )
                    .then(({ data }) => {
                        this.$bvModal.hide('modalReview');
                        this.msgBox(data.result, 'Submit Review');

                        axios.post('/api/nom/nomlist', {})
                            .then(({ data }) => {

                                this.roles = data.roles;
                                this.noms = new sjData(data.noms).data;
                                this.displayPart = 1;
                            })
                            .catch((error) => {
                                if (error) {
                                    this.msgBox('An unexpected error occurred');
                                    //this.msgBox(error.response.data.message);
                                }
                            })


                        //this.noms = new sjData(data.noms).data;
                        //this.selectNom_Loaded(data);

                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox(error.response.data.message);
                            if (error.response.data.formErrors) {
                                this.fReview.errors = error.response.data.formErrors;
                                this.formSetState(this.fReview);//formObj, resetState, resetForm
                            }
                        }
                    })
                    ;
            },

            selectAll(isChecked) {
                //console.log(v);
                if (isChecked) {
                    let sel = [];
                    this.noms.forEach(n => sel.push(n.nomId));
                    this.nomsSelected = sel;
                } else {
                    this.nomsSelected = [];
                }
            },

            windowPrint() {
                window.print();
            },

            exportToCSV() {
                let fx = function (val, fn) {
                    if (typeof fn === "undefined") fn = '';
                    let isNum = (fn === 'num' || typeof val === "number") && !isNaN(val);
                    try {
                        if (isNum) {
                            return val;
                        } else {
                            let s = val.sjReplaceAll("\r\n", " ~ ")
                                .sjReplaceAll("\r", " ~ ")
                                .sjReplaceAll("\n", " ~ ")
                                .sjReplaceAll("\t", "   ")
                                .trim();

                            //ph - phone number - split after 3 chars
                            if (fn == "ph" && s.length > 9) {
                                if (s.substr(0, 1) === "0") {
                                    s = s.sjReplaceAll(' ', '').sjReplaceAll('-', '').sjReplaceAll('(', '').sjReplaceAll(')', '').substr(0, 3) + " " + s.substr(3, 3) + " " + s.substr(6, 4);
                                } else {
                                    s = "'" + s;
                                }
                            }

                            //https://blog.zsec.uk/csv-dangers-mitigations/
                            if (s.indexOf("@") === 1 || s.indexOf("+") === 1 || s.indexOf("-") === 1 || s.indexOf("=") === 1 || s.indexOf("|") === 1 || s.indexOf("%") === 1) {
                                s = "'" + s.sjReplaceAll("|", "\\|");
                            }

                            s = "\"" + s.sjReplaceAll("\"", "\"\"") + "\"";
                            return s;
                        }
                    } catch {
                        return 'Error';
                    }
                }

                let csv = 'Date,Nominee ID,Nominee Name,Nominee Division,Nominated By ID,Nominated By,Nominated By Division,NominatedFor,Reason,Manager ID,Manager,Manager CellNo\r\n';
                //dateCreated,nominee,nomineeDivision,nominatedBy,nomCat,reason,nomineeManager,nomineeManagerCellNum
                for (var n = 0; n < this.nomsFiltered.length; n++) {
                    let f = this.nomsFiltered[n];
                    //debugger;
                    csv += fx(f.dateCreated.sjToDate('d MMM yyyy HH:mm')) + ','
                        + fx(f.nomineeMemberNumber) + ','
                        + fx(f.nomineeMember) + ','
                        + fx(f.nomineeDivision) + ','
                        + fx(f.byMemberNumber) + ','
                        + fx(f.byMember) + ','
                        + fx(f.byDivision) + ','
                        + fx(f.nomCat) + ','
                        + fx(f.reason) + ','
                        + fx(f.nomineeManagerMemberNumber) + ','
                        + fx(f.nomineeManagerMember) + ','
                        + fx(f.nomineeManagerCellNum, "ph") + '\r\n';
                }

                var pom = document.createElement('a');
                pom.href = URL.createObjectURL(new Blob(['\uFEFF', csv], { type: 'text/csv;charset=utf-8;' }));
                pom.setAttribute('download', 'report.csv');
                pom.click();
            },


            //*** Displays a modal of all members in a division  ***
            //showConcludeModal() {
            //	this.entireDivisionName = nominee.division;
            //	let divisionCode = nominee.divisionCode;
            //	this.$bvModal.show('modalEntireDivisionMemberList');
            //	this.entireDivisionMemberList = [];
            //	let axiosParams = null;
            //	if (divisionCode == null) { //no division selected
            //		return;
            //	}

            //	let isEntireDivMemberListCached = typeof this.cachedEntireDivMemberList[divisionCode] !== 'undefined';
            //	if (isEntireDivMemberListCached) {
            //		this.entireDivisionMemberList = this.cachedEntireDivMemberList[divisionCode];
            //		return;
            //	}

            //	axiosParams = { divisionCode: divisionCode, search: '' }; //return all member data for current division
            //	axios.post('/api/site/getmemberlist', axiosParams)
            //		.then(({ data }) => {
            //			this.entireDivisionMemberList = new sjData(data.memberList).data;
            //			this.cachedEntireDivMemberList[divisionCode] = this.entireDivisionMemberList
            //		}
            //		);

            //},



        }

    }
</script>
