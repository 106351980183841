<template v-if="isLoggedIn">
    <div class="container page">
        <div v-if="pgMode=='view' && member" class="card card-default">
            <div class="card-header">
                <h3>My Profile</h3>
            </div>
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-3 fldName">Member Number:</div>
                    <div class="col-md-9 fldValue">{{ member.memberNumber }}</div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-3 fldName">Participating in GET TO KNOW?</div>
                    <div class="col-md-9 fldValue">{{ member.interest1 ? 'Yes' : 'No' }}</div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-3 fldName">Profile Picture:</div>
                    <div class="col-md-9 fldValue"><img id="imgProfilePic" :src="profilePicTempSrc" class="img-fluid" /></div>
                </div>

                <!--<div class="form-group">
        <asp:Label AssociatedControlID="litEmail" runat="server">Receiving daily celebration emails?</asp:Label>
        <p class="form-control-static"><asp:Literal ID="litSendCelebrationEmail" runat="server" /></p>
    </div>
    <hr /> -->
                <!--<div class="form-group">
        <asp:Label AssociatedControlID="litEmail" runat="server">Gift Card:</asp:Label>
        <p class="form-control-static" id="divCardInfo"></p>
    </div> -->
                <hr />

                <div class="row mb-3">
                    <div class="col-md-3 fldName">Name:</div>
                    <div class="col-md-9 fldValue">{{ member.firstname + ' ' + member.surname }}</div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-3 fldName">Division:</div>
                    <div class="col-md-9 fldValue">{{ member.division }}</div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-3 fldName">Email Address:</div>
                    <div class="col-md-9 fldValue">{{ member.email }}</div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-3 fldName">Mobile Number:</div>
                    <div class="col-md-9 fldValue">{{ member.cellNum }}</div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-3 fldName">Date of Birth:</div>
                    <div class="col-md-9 fldValue">{{ formatDOB }}</div>
                </div>

                <hr />
                <div class="row mb-3">
                    <div class="col-md-3 fldName">Get To Know:</div>
                    <div class="col-md-9 fldValue">{{ member.interest1 ? 'Participating in GET TO KNOW' : 'Not Participating in GET TO KNOW'}}</div>
                </div>

                <template v-if="member.interest1">
                    <div class="row mb-3">
                        <div class="col-md-3 fldName">Hobbies And Interests:</div>
                        <div class="col-md-9 fldValue">{{ member.interestOtherDesc }}</div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-md-3 fldName">Dreams And Aspirations:</div>
                        <div class="col-md-9 fldValue">{{ member.otherInfo }}</div>
                    </div>
                </template>

                <content-db contentKey="btmmsgmemberdetails" :isCache="true"></content-db>

            </div>
            <div class="card-footer text-right">
                <router-link :to="{ name: 'MyProfileUpdate', params: { urlParam: 'update' }}" class="btn btn-primary"><span>Update Your Details</span></router-link>
                <!--<router-link to="/myprofile/update" class="btn btn-primary"><span>Update Your Details</span></router-link>-->
            </div>

        </div>

        <div v-if="pgMode=='edit'" class="card card-default">
            <b-form @submit.prevent="onMemberUpdate" novalidate>
                <div class="card-header">
                    <h3>Update Profile</h3>
                </div>
                <div class="card-body">

                    <div v-if="member.cellNum===''" class="alert alert-warning" role="alert" runat="server" id="divCellRequired">
                        We require you to have a mobile number in order to use this portal.<br />Please update your details below.
                    </div>


                    <div class="mb-3">
                        <div class="">Member Number:</div>
                        <div class="fldValue">{{ member.memberNumber }}</div>
                    </div>

                    <b-form-group id="cellNumGrp" label-for="cellNumTxt" label="Mobile Number:" :invalid-feedback="fProfile.errors.cellNum">
                        <b-form-input id="cellNumTxt" v-model="fProfile.form.cellNum" :state="fProfile.state.cellNum" placeholder="" maxlength="20" />
                    </b-form-group>

                    <!--
    <b-form-group id="emailGrp" label-for="emailTxt" label="Email Address:" :invalid-feedback="fProfile.errors.email">
        <b-form-input id="emailTxt" v-model="fProfile.form.email" :state="fProfile.state.email" placeholder="" maxlength="80" />
    </b-form-group>
    -->

                    <div>Profile Picture:</div>
                    <div class="row mb-5">
                        <div class="col-sm-3" id="divUpdateProfilePic">
                            <img id="imgProfilePic" :src="profilePicTempSrc" class="img-fluid" />
                        </div>
                        <div class="col-sm-6">
                            <div>Submit an appropriate picture, face only.</div>
                            <div>
                                <b-form-file v-model="fileProfilePic"></b-form-file>
                            </div>
                            <div>
                                <!--<button title="Rotate Picture" type="button" class="btn btn-default btn-sm" onclick="profilePic.rotate()"><span class="glyphicon glyphicon-repeat" aria-hidden="true"></span> Rotate</button>-->
                            </div>
                        </div>
                    </div>

                    <b-form-group id="firstnameGrp" label-for="firstnameTxt" label="Known As First Name:" description="If you are known as with a different name, enter it here. (Leave blank to reset to your actual first name)" :invalid-feedback="fProfile.errors.firstname">
                        <b-form-input id="firstnameTxt" v-model="fProfile.form.firstname" :state="fProfile.state.firstname" placeholder="" maxlength="80" />
                    </b-form-group>

                    <b-form-group label-for="dobDD" label="Date of Birth:" description="Leave blank if you do not wish to celebrate your birthday. By participating your name and photo (if loaded) will be displayed on Todays Celebration page when the big day comes! You will also receive a birthday card and 100 Beauty Bucks (excludes Sales-Field). If you have already had your birthday this year we will only be celebrating with you next year." :invalid-feedback="fProfile.errors.firstname">
                        <div class="form-inline">
                            <b-form-select id="dobDD" class="mr-1" v-model="fProfile.form.dOBdd" :options="dateOptionsDD"></b-form-select>
                            <b-form-select class="mx-1" v-model="fProfile.form.dOBmm" :options="dateOptionsMM"></b-form-select>
                            <a href="#" @click.prevent="fProfile.form.dOBdd='';fProfile.form.dOBmm='';" class="ml-3">Clear DOB</a>
                        </div>
                    </b-form-group>

                    <b-form-group id="interest1Group" label-for="interest1" description="" :invalid-feedback="fProfile.errors.interest1" :state="fProfile.state.interest1">
                        <template slot="label">
                            Do you wish to participate in
                            <a href="#" @click.prevent="$bvModal.show('modalGetToKnow')">GET TO KNOW</a> ?
                        </template>
                        <b-form-radio-group id="interest1RadioGroup" v-model="fProfile.form.interest1" name="interest1" :state="fProfile.state.interest1">
                            <b-form-radio :value="true">Yes</b-form-radio>
                            <b-form-radio :value="false">No</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>

                    <section v-if="fProfile.form.interest1">
                        <b-form-group id="interestOtherDescGrp" label-for="interestOtherDescTxt" label="Hobbies And Interests:" description="" :invalid-feedback="fProfile.errors.interestOtherDesc">
                            <b-form-input id="interestOtherDescTxt" v-model="fProfile.form.interestOtherDesc" :state="fProfile.state.interestOtherDesc" placeholder="" maxlength="50" />
                        </b-form-group>

                        <b-form-group id="otherInfoGrp" label-for="otherInfoTxt" label="Dreams And Aspirations:" description="" :invalid-feedback="fProfile.errors.otherInfo">
                            <b-form-input id="otherInfoTxt" v-model="fProfile.form.otherInfo" :state="fProfile.state.otherInfo" placeholder="" maxlength="50" />
                        </b-form-group>
                    </section>

                    <hr />
                    <div v-if="doForceMemberUpdate">
                        <b-form-checkbox v-model="termsAccepted">
                            I agree the information provided above is correct.
                            <!--I accept the <a href="#" @click.prevent="$bvModal.show('modalTerms')">terms and conditions</a> of this programme.-->
                        </b-form-checkbox>
                    </div>
                    <div v-else>
                        You have confirmed the information provided above is correct.
                        <!--You have already accepted the <a href="#" @click.prevent="$bvModal.show('modalTerms')">terms and conditions</a> of this programme.-->
                    </div>

                </div>
                <div class="card-footer">
                    <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                </div>
            </b-form>
        </div>
        <b-modal id="modalGetToKnow" scrollable ok-only size="lg" title="About Get To Know">
            <content-db contentKey="gettoknowabout" :isCache="true"></content-db>
        </b-modal>

        <b-modal id="modalTerms" scrollable ok-only size="lg" title="Terms and Conditions">
            <content-db contentKey="terms" :isCache="true"></content-db>
        </b-modal>

        
    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    import ContentDb from '@/components/ContentDb';

    export default {
        name: 'MyProfile',
        components: { ContentDb },
        data() {
            return {
                pgMode: 'view',
                fProfile: {
                    form: { interest1: false, firstname: '', cellNum: '', dOBmm: '', dOBdd: '', interestOtherDesc: '', otherInfo: '' }
                },
                fileProfilePic: null,
                dateOptionsMM: [],
                dateOptionsDD: [],
                picSfx: '0',
                termsAccepted: false
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member;
            },
            doForceMemberUpdate() {
                return this.member.cellNum === '' || this.member.email === ''; //if either cellnum or email is blank, then we need them to force them to accept terms and conditions
            },
            isLoggedIn() {
                return this.$store.state.isLoggedIn;
            },
            profilePicTempSrc() {
                return '/profilepictemp/' + this.$store.state.memberStore.member.picId + '/' + this.picSfx
            },
			formatDOB() {
				if (typeof this.member.dOBdd === 'undefined' || this.member.dOBmm === 'undefined' || this.member.dOBdd === '' || this.member.dOBmm === '') return '(Not provided)';
				let dd = parseInt(this.member.dOBdd);
				let mm = parseInt(this.member.dOBmm);
				if (isNaN(dd) || isNaN(mm) ||
					(dd < 1 || mm < 1 || mm > 12 || dd > 31) ||
					((mm == 4 || mm == 6 || mm == 9 || mm == 11) && dd > 30) ||
					((mm == 2) && dd > 29)) return '(Not provided)';
				let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
				return dd + ' ' + months[mm - 1];
			}
        },

        watch: {
            fileProfilePic: function (newFileProfilePic) {
                if (newFileProfilePic !== null) {
                    this.doFileUpload();
                }
            },

        },

        beforeRouteEnter(to, from, next) {
			next(vm => { // access to component instance via `vm` - i.e. use 'vm' instead of 'this'
               // console.log('*beforeRouteEnter');
                //console.log(to);
				//console.log(from);
                vm.doNavigation();
            });
        },

        created() {

            this.formSetState(this.fProfile, true, true) //formObj, resetState, resetForm

            this.dateOptionsDD = [];
            this.dateOptionsMM = [];

            this.dateOptionsDD.push({ value: null, text: '' });
            this.dateOptionsMM.push({ value: null, text: '' });

            for (var d = 1; d < 32; d++) {
                this.dateOptionsDD.push({ value: ('0' + d).substr(-2), text: ('' + d) });
            }

            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            for (var m = 1; m <= 12; m++) {
                this.dateOptionsMM.push({ value: ('0' + m).substr(-2), text: months[m - 1] });
            }

            this.doNavigation();
        },
        mounted() {
            this.doGetMember();

            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
        methods: {
            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
            newPicTemp() {
                this.picSfx = ((new Date).getTime() - 1);
            },
            doNavigation() {
                const param = typeof this.$route.params.urlParam === 'undefined' ? '' : this.$route.params.urlParam;//  this.urlParam;
                console.log('doNavigation route param:');
                console.log(param);

                if (param === 'update') {
                    this.pgMode = 'edit'
                } else {
                    this.pgMode = 'view'
                }
            },
            doGetMember() {

                axios.post('/api/member/getmemberdata', { resetimg: true })
                    .then(({ data }) => {

                        this.formSetState(this.fProfile, true, true);//formObj, resetState, resetForm
                        let memdata = data.memberData;
                        var fields = Object.getOwnPropertyNames(this.fProfile.form);

                        for (let i = 0; i < fields.length; i++) {
                            let field = fields[i];
                            this.fProfile.form[field] = memdata[field];
                        }

                        this.newPicTemp();
                        this.doNavigation();
                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox('Error retrieving member data');
                        }
                    })
            },
            doFileUpload() {
                let formData = new FormData();

				formData.append('filePic', this.fileProfilePic, this.fileProfilePic.name);
                axios.post('/api/member/profilepicupload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(() => {
                        this.newPicTemp();
                    })
                    .catch(function () {
                        alert('FAILURE UPLOAD!!');
                    });
            },

            onMemberUpdate() {
                if (this.doForceMemberUpdate && !this.termsAccepted) {
                    this.msgBox('In order to participate in this program you are required to ensure the information provided is correct');
                    //this.msgBox('Terms and Conditions are required to be accepted to participate in this program');
                    return;
                }

                if (!this.fProfile.form.interest1) { //Not participating so clear get to know
                    this.fProfile.form.interestOtherDesc = '';
                    this.fProfile.form.otherInfo = '';
                }

                this.formSetState(this.fProfile, true);//formObj, resetState, resetForm

                return axios
                    .post(
                        '/api/member/memberupdate',
                        this.fProfile.form
                    )
                    .then(({ data }) => {
                        console.log(data);
                        this.$store.dispatch('memberStore/getMemberData');
                        this.$store.dispatch('memberStore/doSetProfilePicSfx');
                        this.msgBox('Thank you for updating your details.');
                        this.$router.push({ name: 'MyProfile' });
                    })
                    .catch((error) => {
                        if (error && error.response) {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fProfile.errors = error.response.data.formErrors;
                                this.formSetState(this.fProfile);
                            }
                        } else {
                            this.msgBox('Unexpected Error');
                        }
                    });
            }
        }

    }
</script>
