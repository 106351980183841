<template>
    <div class="margin-top:100px;">

        <h1>Logout</h1>
        <router-view class="page" />
        <h3>Please wait, logging out</h3>
        <hr />
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        created() {
            this.$store.dispatch('memberStore/doLogout');
            setTimeout(function () {
                window.location.href = "/";
            }, 1000)
        },
        methods: {
            login() {
                window.location.href = "/";
                return;
            }
        }
    }
</script>
