import axios from 'axios'
import sjData from '../../sjUtilData'

export default {
    namespaced: true,
    state: () => ({
        isMemberLoaded: false,
        member: {},
        homeRecentRecognition: {},
        homeGetToKnow: {},
        homeStats: {},
        homeCelebrations: {},
        profilepicSfx: ''
    }),


    actions: {
        //Called by components/AuthSSO.vue
        authsso({ commit }, params) {
            sessionStorage.removeItem('SecBr'); //SecBr = Secret Bearer
            return axios
                .post(
                    '/api/member/authsso',
                    params, { headers: { Authorization: '' } } //We must not send a valid authorisation headerJWT, otherwise will cause an error if cookie is invalid
                )
                .then(({ data }) => {
                    commit('SET_BEARER_TOKEN', data.token)
                    commit('SET_MEMBER_DATA', data);
                    commit('SET_LOGIN_DATA', data, { root: true });
                })
        },
        loggedIn({ commit }, data) {
            sessionStorage.removeItem('SecBr'); //SecBr = Secret Bearer
            commit('SET_BEARER_TOKEN', data.token)
            commit('SET_MEMBER_DATA', data);
            commit('SET_LOGIN_DATA', data, { root: true });
        },
        getMemberData({ commit }) {
            return axios.post('/api/member/getmemberdata', {})
                .then(({ data }) => {// data.memberData.SiteTemplate = 'santam'; //***** Override Site
                    commit('SET_MEMBER_DATA', data);
                    commit('SET_LOGIN_DATA', data, { root: true });
                })
        },
        doSessionLost({ commit }) {
            commit('CLEAR_LOGIN_DATA', null, { root: true })
            location.href = process.env.BASE_URL + 'logout';
        },
        doLogout({ commit }) {
            commit('CLEAR_LOGIN_DATA', null, { root: true })
        },
        doSetProfilePicSfx({ commit }) {
            commit('SET_PROFILE_PIC_SFX', null, { root: false })
        }
    },
    mutations: {
        SET_BEARER_TOKEN(state, token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            sessionStorage.setItem('SecBr', token)
        },

        SET_MEMBER_DATA(state, data) {
            state.member = data.memberData;
            state.homeGetToKnow = new sjData(data.homeGetToKnow).data;
            state.homeRecentRecognition = new sjData(data.homeRecentRecognition).data;
            state.homeStats = data.homeStats;
            state.homeCelebrations = new sjData(data.homeCelebrations).data;
            state.isMemberLoaded = true;
        },

        SET_PROFILE_PIC_SFX(state) {
            console.log('profilepicSfx');
            state.profilepicSfx = '?r=' + ((new Date).getTime() - 1)
        }
    }
}