<template>
    <section class="container-fluid">
        <div><img src="../assets/image/helloBanner.jpg" class="w-100 mb-5" /></div>
        <div class="text-center my-5"><img src="../assets/image/helloWeAreAvonTitle.png" class="img-fluid" /></div>
        <div class="row my-5 no-gutters">
            <div class="col-sm-8 offset-sm-2  col-lg-4 offset-lg-4 px-xl-5 text-center">
                We value you for being you and are committed to providing opportunities for you to grow and thrive. We create an environment where you can do purposeful work and have a meaningful career. We embrace individuality and know it's beautiful to be you. Relationships are at the heart of everything we do and we empower you to work in a way that works for you. We recognise your contributions and offer a fair deal.
            </div>
        </div>

        <div class="my-5 text-center">
            <button type="button" name="cmdlogin" class="btn btn-primary btn-md" @click="isShowPassword=false; $bvModal.show('modalLogin');">LOGIN</button>
        </div>

        <div class="row">
            <div class="offset-sm-2 col-sm-5 text-center text-sm-right d-flex align-items-center my-3 my-lg-5">
                <img src="../assets/image/helloWhyBanner.jpg" class="img-fluid px-3" />
            </div>
            <div class="col-sm-4 col-lg-2 text-left text-sm-left d-flex align-items-center my-3 my-lg-5">
                <div>
                    <div><img src="../assets/image/helloWhyTitle.png" class="img-fluid mr-3 my-5" /></div>
                    <p>We use the <b>power of beauty</b> to <b>transform</b> women's lives</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="offset-sm-2 col-sm-5 col-lg-4 text-left text-sm-left d-flex align-items-center my-3 my-lg-5">
                <div>
                    <div><img src="../assets/image/helloWhatTitle.png" class="img-fluid mr-3 my-5" /></div>
                    <div><b>We believe...</b></div>
                    <ul class="pl-4">
                        <li>In a better world for women, which is a better world for all.</li>
                        <li>In beauty democracy: inclusive beauty that is open to all.</li>
                        <li>Everyone is equal. We champion gender equality.</li>
                        <li>In creating flexible opportunities for anyone to earn and learn.</li>
                        <li>In supporting NGOs and causes to help women's health and wellbeing, with a focus on breast cancer and violence against women and girls.</li>
                    </ul>

                </div>
            </div>
            <div class="col-sm-3 col-lg-4 text-center text-sm-left d-flex align-items-center my-3 my-lg-5">
                <img src="../assets/image/helloWhatBanner.jpg" class="img-fluid" />
            </div>
        </div>

        <div class="text-center my-5"><img src="../assets/image/helloWhereBanner.jpg" class="img-fluid" /></div>


        <div class="row">
            <div class="offset-sm-2 col-sm-4 col-lg-4 text-left text-sm-left d-flex align-items-center my-3 my-lg-5">
                <div>
                    <div><img src="../assets/image/helloHowWeActTitle.png" class="img-fluid mr-3 my-4" /></div>
                    <p><b>More than a set of buzzwords, these are the values we live and breathe, day in and day out.</b></p>
                </div>
            </div>
            <div class="col-sm-4 text-center text-sm-left d-flex align-items-center my-3 my-lg-5">
                <img src="../assets/image/helloHowWeActBanner.jpg" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="offset-sm-2 col-sm-8">
                <div class="row">
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock1">
                            <div class="welcomeTitle">BELIEF</div>
                            <p>We believe our people, and our business, have the  power to beat the odds and to succeed. When you believe in someone and show it, they will  move mountains to prove you're right.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock1">
                            <div class="welcomeTitle">INTEGRITY</div>
                            <p>We simply do what's right. We set the highest ethical standards, upholding a duty of care to our representatives, our customers, our associates, the communities we serve and ourselves.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock1">
                            <div class="welcomeTitle">RESPECT</div>
                            <p>We value differences and appreciate each person for  their unique qualities. Because respect helps to bring out the full potential in everyone.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock1">
                            <div class="welcomeTitle">TRUST</div>
                            <p>We build environments that are safe and open - where  people feel free to take risks, to share their point of view  and to speak the truth as they see it.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock1">
                            <div class="welcomeTitle">HUMILITY</div>
                            <p>We're not always right - we don't have all the  answers - and we know it. We're always learning,  and we're not afraid to ask for help.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="offset-sm-2 col-sm-4 col-lg-4 text-left text-sm-left d-flex align-items-center my-3 my-lg-5">
                <div>
                    <div><img src="../assets/image/helloHowWeWorkTitle.png" class="img-fluid mr-3 my-4" /></div>
                    <p><b>We work hard and we're proud to be part of a collective that lives the Avon brand.</b></p>
                </div>
            </div>
            <div class="col-sm-4 text-center text-sm-left d-flex align-items-center my-3 my-lg-5">
                <img src="../assets/image/helloHowWeWorkBanner.jpg" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="offset-sm-2 col-sm-8">
                <div class="row">
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock2">
                            <div class="welcomeTitle">BEAUTY OBSESSED</div>
                            <p>We are obsessed about beauty and proud of  our products. We are brand advocates.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock2">
                            <div class="welcomeTitle">REPRESENTATIVE  AND CUSTOMER FOCUSED</div>
                            <p>We deliver an exceptional experience for our Representatives and consumers. We are open for all, anytime and anywhere.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock2">
                            <div class="welcomeTitle">OPEN & CURIOUS</div>
                            <p>We are always learning and raising our game, with an appetite for change and a thirst for innovation.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock2">
                            <div class="welcomeTitle">SIMPLE & AGILE</div>
                            <p>We cut through complexity with an entrepreneurial spirit to think differently and act quickly, challenging ourselves  to work smarter and faster</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock2">
                            <div class="welcomeTitle">BOLD & FEARLESS</div>
                            <p>We are courageous, speaking up for what we believe is right, acting with integrity and tirelessly challenging the status quo.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock2">
                            <div class="welcomeTitle">WINNING AS ONE TEAM</div>
                            <p>We relentlessly drive for growth, taking personal responsibility and collective accountability to break down silos and deliver what we promise.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="row">
            <div class="offset-sm-2 col-sm-4 col-lg-4 text-left text-sm-left d-flex align-items-center my-3 my-lg-5">
                <div>
                    <div><img src="../assets/image/helloGetInReturnTitle.png" class="img-fluid mr-3 my-4" /></div>
                    <p><b>We value you for being you and are committed to providing opportunities for you to grow and thrive.</b></p>
                </div>
            </div>
            <div class="col-sm-4 text-center text-sm-left d-flex align-items-center my-3 my-lg-5">
                <img src="../assets/image/helloGetInReturnBanner.jpg" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="offset-sm-2 col-sm-8">
                <div class="row">
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock3">
                            <div class="welcomeTitle">PURPOSEFUL WORK</div>
                            <p>We provide opportunities for you to make a difference to our representatives and to play a role in making a better world.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock3">
                            <div class="welcomeTitle">MEANINGFUL CAREERS</div>
                            <p>We provide opportunities for you to do your best work and realise your full potential, whatever your career aspirations. </p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock3">
                            <div class="welcomeTitle">BEAUTIFUL TO BE YOU</div>
                            <p>We see the beauty in our differences so that you have the confidence bring your whole self to work.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock3">
                            <div class="welcomeTitle">RELATIONSHIPS AT HEART</div>
                            <p>We value the connections we make with each other. We put your mental and physical wellbeing first and provide a supportive and inclusive culture.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock3">
                            <div class="welcomeTitle">WORK THAT WORKS FOR YOU</div>
                            <p>We empower you to deliver and support flexible working to help you perform at your best.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-4 d-flex align-items-stretch justify-content-center mb-5">
                        <div class="welcomeBlock3">
                            <div class="welcomeTitle">A FAIR DEAL</div>
                            <p>We pay at market rate and recognise individual and business performance through our annual bonus programme.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <!-- ************************************************************ -->
        <!-- -------------------- MODALS ---------------------------  -->
        <!-- *********************************************************** -->

        <b-modal id="modalLogin" title="LOGIN" hide-footer centered no-close-on-backdrop>
            <div class="container">
                <p>Please enter your login details</p>

                <b-form class="helloForm" @submit.prevent="onLogin" novalidate>

                    <b-form-group id="memberNumberNumGrp" label-for="memberNumbertxt" label="Employee Number:">
                        <b-form-input id="memberNumberTxt" v-model="fLogin.form.memberNumber" />
                        <small>{{fLogin.errors.memberNumber}}</small>
                    </b-form-group>

                    <b-form-group id="passwordGrp" label-for="passwordtxt" label="Password:">
                        <b-form-input type="password" id="passwordTxt" v-model="fLogin.form.password" />
                        <small>{{fLogin.errors.password}}</small>
                    </b-form-group>

                    <div class="row mt-4">
                        <div class="col-md-4">
                            <button type="submit" class="btn btn-primary btn-md">
                                Login
                            </button>
                        </div>
                        <div class="col-md-8 text-right">
                            <b-button class="forgotBtn" variant="link" @click.prevent="isShowPassword=false; formSetState(fPassword, true, true);pgModePassword=='view';$bvModal.show('modalPassword')">
                                Forgotten your password?
                            </b-button>
                        </div>
                    </div>
                </b-form>

                <hr class="mt-5" />

                <div class="row">
                    <div class="col-md-8">Need an Account?</div>
                    <div class="col-md-4">
                        <button @click.prevent="isShowPassword=false; $bvModal.hide('modalLogin');$bvModal.show('modalRegister')" class="btn btn-primary">
                            Register
                        </button>
                    </div>
                </div>
                <!--<div class="mt-1 row">
                <b-button class="forgotBtn" variant="link">FAQs</b-button>
                <b-button class="ml-auto forgotBtn" variant="link">Terms</b-button>
            </div>-->
                <hr />
            </div>
        </b-modal>


        <b-modal id="modalPassword" scrollable ok-only size="lg" title="Forgot Password" hide-footer no-close-on-backdrop>

            <div v-if="pgModePassword=='view'" class="card card-default">
                <b-form @submit.prevent="onPassword" novalidate>

                    <div class="card-body">
                        <!--
                        <b-form-group id="surnameGrp" label-for="surnameTxt" label="Surname:" :invalid-feedback="fProfile.errors.surname" description="Your surname must match that on our records.">
                            <b-form-input id="surnameTxt" v-model="fProfile.form.surname" :state="fProfile.state.surname" placeholder="" maxlength="80" />
                        </b-form-group>
                    -->
                        <!--Fp = Forgot Password-->

                        <b-form-group id="cellNumGrpFp" label-for="cellNumTxtFp" label="Mobile Number:" :invalid-feedback="fPassword.errors.cellNum" description="We will send you a one time pin to verify your mobile number.">
                            <b-form-input id="cellNumTxtFp" v-model="fPassword.form.cellNum" :state="fPassword.state.cellNum" placeholder="" maxlength="20" />
                        </b-form-group>

                        <b-form-group id="passwordGrp" label-for="passwordTxt" label="Password:" description="Enter a password of at least 8 characters that includes at least 1 letter and 1 number." :invalid-feedback="fPassword.errors.password">
                            <b-input-group>
                                <b-form-input :type="isShowPassword ? 'text' : 'password'" id="passwordTxt" v-model="fPassword.form.password" :state="fPassword.state.password" placeholder="" maxlength="80" />
                                <b-input-group-append>
                                    <b-button @click="isShowPassword = !isShowPassword" :variant="isShowPassword ? 'outline-primary' : 'outline-secondary'"><b-icon icon="eye-fill" aria-hidden="true"></b-icon></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>

                    </div>
                    <div class="card-footer">
                        <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                    </div>
                </b-form>
            </div>

            <div v-if="pgModePassword=='cellverify'" class="card card-default">
                <b-form @submit.prevent="onPasswordOtpVerify" novalidate>
                    <div class="card-header">
                        We have sent a one time pin to {{ fPassword.form.cellNum }}. Please enter it here to confirm registration:
                    </div>
                    <div class="card-body">
                        <b-form-group id="otpGrp" label-for="otpTxt" label="One Time Pin (OTP):" :invalid-feedback="fPassword.errors.otp" description="Enter the OTP you recieve by SMS.">
                            <b-form-input id="otpTxt" v-model="fPassword.form.otp" :state="fPassword.state.otp" placeholder="" maxlength="20" />
                        </b-form-group>
                    </div>
                    <div class="card-footer">
                        <div class="text-right"><b-button @click="pgModePassword='view'">Back</b-button> <b-button class="ml-3" type="submit" variant="primary">Submit</b-button></div>
                    </div>
                </b-form>
            </div>

            <div v-if="pgModePassword=='done'" class="card card-default">
                <div class="card-header">
                    Thank you
                </div>
                <div class="card-body">
                    You have successfully updated your password, and may now login using your new credentials.
                </div>
                <div class="card-footer">
                    <div class="text-right"><b-button @click="pgModePassword='view';$bvModal.hide('modalPassword')">Close</b-button></div>
                </div>
            </div>

        </b-modal>



        <!-- Modal  -->
        <b-modal id="modalRegister" size="lg" title="REGISTER" hide-footer no-close-on-backdrop>

            <div v-if="pgMode=='view'" class="card card-default">
                <b-form @submit.prevent="onMemberRegister" novalidate>

                    <div class="card-body">

                        <b-form-group id="memberNumberGrp" label-for="memberNumberTxt" label="Employee Number:" :invalid-feedback="fProfile.errors.memberNumber" description="">
                            <b-form-input id="memberNumberTxt" v-model="fProfile.form.memberNumber" :state="fProfile.state.memberNumber" placeholder="" maxlength="80" />
                        </b-form-group>

                        <b-form-group id="emailGrp" label-for="emailTxt" label="Email Address:" :invalid-feedback="fProfile.errors.email" description="Your email address must match the email address on our records">
                            <b-form-input id="emailTxt" v-model="fProfile.form.email" :state="fProfile.state.email" placeholder="" maxlength="80" />
                        </b-form-group>

                        <!--<b-form-group id="firstnameGrp" label-for="firstnameTxt" label="First Name:" :invalid-feedback="fProfile.errors.firstname">
                        <b-form-input id="firstnameTxt" v-model="fProfile.form.firstname" :state="fProfile.state.firstname" placeholder="" maxlength="80" />
                    </b-form-group>

                    <b-form-group id="surnameGrp" label-for="surnameTxt" label="Surname:" :invalid-feedback="fProfile.errors.surname">
                        <b-form-input id="surnameTxt" v-model="fProfile.form.surname" :state="fProfile.state.surname" placeholder="" maxlength="80" />
                    </b-form-group>-->


                        <b-form-group id="cellNumGrp" label-for="cellNumTxt" label="Mobile Number:" :invalid-feedback="fProfile.errors.cellNum" description="We will send you a one time pin to verify your mobile number.">
                            <b-form-input id="cellNumTxt" v-model="fProfile.form.cellNum" :state="fProfile.state.cellNum" placeholder="" maxlength="20" />
                        </b-form-group>

                        <b-form-group id="passwordGrp" label-for="passwordTxt" label="Password:" description="Enter a password of at least 8 characters that includes at least 1 letter and 1 number." :invalid-feedback="fProfile.errors.password">
                            <b-input-group>
                                <b-form-input :type="isShowPassword ? 'text' : 'password'" id="passwordTxt" v-model="fProfile.form.password" :state="fProfile.state.password" placeholder="" maxlength="80" />
                                <b-input-group-append>
                                    <b-button @click="isShowPassword = !isShowPassword" :variant="isShowPassword ? 'outline-primary' : 'outline-secondary'"><b-icon icon="eye-fill" aria-hidden="true"></b-icon></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>

                        <b-form-checkbox v-model="termsAccepted">
                            I accept the <a href="#" @click.prevent="$bvModal.show('modalTerms')">terms and conditions</a> of this programme.
                        </b-form-checkbox>

                    </div>
                    <div class="card-footer">
                        <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                    </div>
                </b-form>
            </div>

            <div v-if="pgMode=='cellverify'" class="card card-default">
                <b-form @submit.prevent="onOtpVerify" novalidate>
                    <div class="card-header">
                        We have sent a one time pin to {{ fProfile.form.cellNum }}. Please enter it here to confirm registration:
                    </div>
                    <div class="card-body">
                        <b-form-group id="otpGrp" label-for="otpTxt" label="One Time Pin (OTP):" :invalid-feedback="fProfile.errors.otp" description="Enter the OTP you recieve by SMS.">
                            <b-form-input id="otpTxt" v-model="fProfile.form.otp" :state="fProfile.state.otp" placeholder="" maxlength="20" />
                        </b-form-group>
                    </div>
                    <div class="card-footer">
                        <div class="text-right"><b-button @click="pgMode='view'">Back</b-button> <b-button class="ml-3" type="submit" variant="primary">Submit</b-button></div>
                    </div>
                </b-form>
            </div>

            <div v-if="pgMode=='done'" class="card card-default">
                <div class="card-header">
                    Thank you
                </div>
                <div class="card-body">
                    <p>You have successfully registered.</p>
                    <p>You may now login using your credentials.</p>
                </div>
                <div class="card-footer">
                    <div class="text-right"><b-button @click="pgMode='view';$bvModal.hide('modalRegister')">Close</b-button></div>
                </div>
            </div>


        </b-modal>
    </section>
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    //import ContentDb from '@/components/ContentDb';

    export default {
        name: 'Welcome',
        components: {  },
        data() {
            return {
                pgMode: 'view',
                fLogin: {
                    form: { memberNumber: '', password: '' }
                },
                fProfile: {
					form: { memberNumber: '', email: '', cellNum: '', password: '', otp: '', auth: '' } //, firstname: '', surname: ''
                },
                pgModePassword: 'view',

                fPassword: {
					form: { cellNum: '', password: '', otp: '', auth: '' }
                },
                termsAccepted: false,
                isShowPassword : false,
            }
        },
        computed: {

        },
        props: {
        },
        watch: {
            //'$route': 'doNavigation',
        },
        created() {
            this.formSetState(this.fLogin, true, true); //formObj, resetState, resetForm
            this.formSetState(this.fProfile, true, true); //formObj, resetState, resetForm
			this.formSetState(this.fPassword, true, true); //formObj, resetState, resetForm

        },
        mounted() {

        },
        methods: {
            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
            //doNavigation() {
            //    const param = this.urlParam;//this.$route.params.urlParam;//  this.urlParam;

            //    if (param === 'update') {
            //        //console.log('doNavigationBasket' + param);
            //        this.pgMode = 'edit'
            //    } else {
            //        this.pgMode = 'view'
            //    }
            //},

            onMemberRegister() {
                if (!this.termsAccepted) {
                    this.msgBox('Terms and Conditions are required to be accepted to participate in this program');
                    return;
                }
                this.fProfile.form.otp = '';
                this.fProfile.form.auth = '';
                this.formSetState(this.fProfile, true);//formObj, resetState, resetForm

                return axios
                    .post(
                        '/api/member/sendcell2factorsms',
                        this.fProfile.form
                    )
                    .then(({ data }) => {
                        this.fProfile.form.auth = data.auth;
                        console.log(data);
                        this.pgMode = 'cellverify';
                    })
                    .catch((error) => {
                        if (error && error.response) {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fProfile.errors = error.response.data.formErrors;
                                this.formSetState(this.fProfile);
                            }
                        } else {
                            this.msgBox('Unexpected Error');
                        }
                    });
            },

            onOtpVerify() {
                //if (!this.fProfile.form.otp.length != 6) {
                //                this.msgBox('Invalid OTP entered');
                //                return;
                //            }

                return axios
                    .post(
                        '/api/member/cell2factorverify',
                        this.fProfile.form
                    )
                    .then(({ data }) => {
                        console.log(data);
                        this.pgMode = 'done';
                    })
                    .catch((error) => {
                        if (error && error.response) {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fProfile.errors = error.response.data.formErrors;
                                this.formSetState(this.fProfile);
                            }
                        } else {
                            this.msgBox('Unexpected Error');
                        }
                    });
            },

			onLogin() {
				//if (!this.fProfile.form.otp.length != 6) {
				//                this.msgBox('Invalid OTP entered');
				//                return;
				//            }

				return axios
					.post(
						'/api/member/login',
						this.fLogin.form
					)
					.then(({ data }) => {
                        console.log(data);

						this.$store
							.dispatch('memberStore/loggedIn', data)
							.then(() => {
								this.$router.replace({ name: 'Home' }) //we use replace instead of push - to replace the current history entry
							})
							.catch(err => {
								let msg = err.message;
								this.msgBox('Error occurred in calling [memberStore/loggedIn]: ' + msg);
							})
                    })
					.catch((error) => {
						if (error && error.response) {
							this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
							if (error.response.data.formErrors) {
								this.fLogin.errors = error.response.data.formErrors;
								this.formSetState(this.fProfile);
							}
						} else {
							this.msgBox('Unexpected Error');
						}
					});
            },

			onPassword() {
				this.fPassword.form.otp = '';
				this.fPassword.form.auth = '';
				this.formSetState(this.fPassword, true);//formObj, resetState, resetForm

				return axios
					.post(
						'/api/member/passwordsendcell2factorsms',
						this.fPassword.form
					)
					.then(({ data }) => {
						this.fPassword.form.auth = data.auth;
						console.log(data);
						this.pgModePassword = 'cellverify';
					})
					.catch((error) => {
						if (error && error.response) {
							this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
							if (error.response.data.formErrors) {
								this.fPassword.errors = error.response.data.formErrors;
								this.formSetState(this.fPassword);
							}
						} else {
							this.msgBox('Unexpected Error');
						}
					});
			},

			onPasswordOtpVerify() {
				//if (!this.fPassword.form.otp.length != 6) {
				//                this.msgBox('Invalid OTP entered');
				//                return;
				//            }

				return axios
					.post(
						'/api/member/passwordcell2factorverify',
						this.fPassword.form
					)
					.then(({ data }) => {
						console.log(data);
						this.pgModePassword = 'done';
					})
					.catch((error) => {
						if (error && error.response) {
							this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
							if (error.response.data.formErrors) {
								this.fPassword.errors = error.response.data.formErrors;
								this.formSetState(this.fPassword);
							}
						} else {
							this.msgBox('Unexpected Error');
						}
					});
			},

            
        }

    }
</script>
