<template v-if="isLoggedIn">
    <div class="container page">
        <!--<h4>Get Involved</h4>-->
        <content-db contentKey="topmsggetinvolved" :isCache="true"></content-db>
        <div class="mt-3">
            <b-nav>
                <b-nav-item-dropdown id="my-nav-dropdown"
                                     text="Themes"
                                     toggle-class="nav-link-custom"
                                     >

                    <b-dropdown-item v-for="campaign in campaigns" :key="campaign._rowNum">
                        <b-nav-item @click="campaignIdx=campaign._rowNum; showCampaignPics()" :style="{ color:'#fff', border: 'solid 2px #' + campaign.campaignColour, padding: '5px'  }">{{campaign.campaignName}}</b-nav-item>
                    </b-dropdown-item>
                </b-nav-item-dropdown>

                <!--<b-nav-item @click.prevent="$bvModal.show('modalAddPic')">My Profile</b-nav-item>-->
                <b-nav-item @click.prevent="showProfilePics()">My Profile</b-nav-item>

            </b-nav>
        </div>
        <hr />

        <h1 v-if="typeof campaignData !== 'undefined'">{{ campaignData.campaignName + (isCurrentCampaign ? '' : ' (Previous Theme)') }}</h1>
        <div class="row">
            <section v-for="mempics in picdata" :key="mempics._rowNum" class="getinvolved col-md-3 col-sm-4 col-6">
                <template v-if="mempics.length==1">
                    <get-involved-pic :pic="mempics[0]" :mode="mode" @like-update="likeUpdate($event)" @delete-pic="deletePic($event)" />
                </template>
                <template v-else>
                    <b-carousel id="homeGtkCarousel" :interval="3700" controls
                                img-width="1024"
                                img-height="480">
                        <!--
                    controls
                    indicators
                    v-model="slide"
                    :interval="3700"
                    background="#ababab"
                    img-width="1024"
                    img-height="480"
                    style="text-shadow: 1px 1px 2px #333;"
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                    -->
                        <template>
                            <b-carousel-slide v-for="pic in mempics" :key="pic._rowNum">
                                <template v-slot:img>
                                    <get-involved-pic :pic="pic" :mode="mode" @like-update="likeUpdate($event)" @delete-pic="deletePic($event)" />
                                </template>
                            </b-carousel-slide>
                        </template>
                    </b-carousel>
                </template>
            </section>
        </div>
        <div v-if="mode=='myprofile'">
            <button title="Upload a new Picture" type="button" class="btn btn-primary" @click="$bvModal.show('modalAddPic')">Add a new picture</button>
        </div>




        <b-modal id="modalAddPic" scrollable size="lg" title="Add a picture" @ok="saveUploadedPic">
            <div class="row mb-5">
                <div class="col-sm-3" id="divUpdateProfilePic">
                    <img id="imgPic" :src="picSrcTemp" v-if="picSfx" class="img-fluid" />
                </div>
                <div class="col-sm-6">
                    <div>Upload an new picture:</div>
                    <div>
                        <b-form-file v-model="filePic"></b-form-file>
                    </div>
                    <div>
                        <button title="Rotate Picture" type="button" class="btn btn-default btn-sm" @click.prevent="rotate()"><span class="glyphicon glyphicon-repeat" aria-hidden="true"></span> Rotate</button>
                    </div>
                </div>
            </div>
            <b-form-checkbox v-model="savePicTermsAccepted">
                I accept the <a href="#" @click.prevent="$bvModal.show('modalGetInvolvedTerms')">terms and conditions</a>.
            </b-form-checkbox>

        </b-modal>

        <b-modal id="modalGetInvolvedTerms" scrollable ok-only size="lg" title="Terms and Conditions">
            <content-db contentKey="getinvolvedterms" :isCache="true"></content-db>
        </b-modal>

    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'
    import GetInvolvedPic from '@/components/GetInvolvedPic';
    import ContentDb from '@/components/ContentDb';
    //import { forEach } from 'core-js/fn/array';


    export default {
        name: 'GetInvolved',
        components: { GetInvolvedPic, ContentDb },
        data() {
            return {
                campaigns: [],
                campaignIdx: null,
                isLoading: false,
                filePic: null,
                picSfx: '',
                angle: 0,
                savePicTermsAccepted: false,
                picdata: [],
                mode: '',
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },

            picSrcTemp() {
                return '/getinvolvedpictmp/' + this.$store.state.memberStore.member.picId + '/' + this.picSfx
            },

            campaignData() {
                return this.campaigns[this.campaignIdx];
            },

            isCurrentCampaign() {
                return this.campaignIdx === 0;
            },

        },

        created() {
            this.loadCampaigns();
        },
        mounted() {

        },
        watch: {
            filePic: function (newFileProfilePic) {
                if (newFileProfilePic !== null) {
                    this.doFileUpload();
                }
            },

        },

        methods: {
            loadCampaigns() {
                this.isLoading = true;
                this.report = [];
                axios.post('/api/getinvolved/getcampaigns', {})
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.campaigns = new sjData(data.campaigns).data;
                        if (this.campaigns.length !== 0) {
                            this.campaignIdx = 0;
                            this.showCampaignPics();
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log('Error in Load Campaigns');
                        console.log(error);

                        this.msgBox('Error in Load Campaigns');
                    })
            },
            doFileUpload() {
                let formData = new FormData();
                this.angle = 0;
                formData.append('filePic', this.filePic, this.filePic.name);
                this.isLoading = true;
                axios.post('/api/getinvolved/picupload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(() => {
                        this.newPicTemp();
                        this.picSfx = '';
                        this.isLoading = false;
                    })
                    .catch(() => {
                        this.isLoading = false;
                    })
            },
            newPicTemp() {
                this.picSfx = ((new Date).getTime() - 1);
            },
            rotate() {
                this.angle++;
                if (this.angle > 3) this.angle = 0;
                this.isLoading = true;
                axios.post('/api/getinvolved/imagerotate', { angle: this.angle })
                    .then(() => {
                        this.newPicTemp();
                    })
                    .catch(() => {
                        this.isLoading = false;
                        this.picSfx = '';
                    })
            },
			saveUploadedPic(bvModalEvt) {
                bvModalEvt.preventDefault();
                if (!this.savePicTermsAccepted) {
                    this.msgBox('You are required to accept the terms and conditions before publishing your picture', 'Terms and Conditions');
                    return;
                }
                axios.post('/api/getinvolved/saveuploadedimage', {})
                    .then(() => {
                        this.showProfilePics();
                        this.$nextTick(() => {
                            this.picSfx = '';
                            this.$bvModal.hide('modalAddPic');
                        })
                    })
                    .catch(() => {
                        this.isLoading = false;
                    })
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },


            realTypeOf: function (obj) {
                //Returns the type of an object. i.e. String,Boolean,Date,Number, etc  http://mrrena.blogspot.com/2012/05/javascript-better-typeof-accurately.html
                return Object.prototype.toString.call(obj).slice(8, -1);
            },

            fixPicData(data) {
                let d = [];
                let y;
                for (y = 0; y < data.length; y++) {
                    let sortId = data[y].sortId;
                    let nextSortId = y === data.length - 1 ? -1 : data[y + 1].sortId;
                    let g = new Array();
                    if (sortId === nextSortId) { //When sortId is the same, it means belongs to same member
                        let cnt = 0;
                        for (var y1 = y; y1 < data.length; y1++) {
                            sortId = data[y1].sortId;
                            nextSortId = y1 == data.length - 1 ? -1 : data[y1 + 1].sortId;
                            data[y1].cnt = cnt;
                            g.push(data[y1]);
                            if (sortId != nextSortId) {
                                break;
                            }
                            cnt++;
                        }
                        y = y1;
                    } else { //only a single pic for this member
                        data[y].cnt = 0;
                        g.push(data[y]);
                    }
                    d.push(g);
                }
                return d;
            },

            showCampaignPics() {
                let campaignId = this.campaignData.campaignId;
                if (!campaignId || campaignId == 0) return;
                this.isLoading = true;
                this.campaignId = campaignId;
                axios.post('/api/getinvolved/getcampaignpics', { campaignId: campaignId })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.picdata = this.fixPicData(new sjData(data.picdata).data);
                        this.mode = 'campaign';
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log('Error in Show Campaign Pics');
                        console.log(error);
                        this.msgBox('Error in Show Campaign Pics');
                    })
            },

            showProfilePics() {
                axios.post('/api/getinvolved/getprofilepics', {})
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.picdata = this.fixPicData(new sjData(data.picdata).data);
                        this.mode = 'myprofile';
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log('Error in Show Profile Pics');
                        console.log(error);
                        this.msgBox('Error in Show Profile Pics');
                    })
            },
            likeUpdate(e) {
                let getInvolvedId = e.getInvolvedId;
                let totalLikes = e.likes;
                for (let y = 0; y < this.picdata.length; y++) {
                    let mempics = this.picdata[y];
                    for (let y1 = 0; y1 < mempics.length; y1++) {
                        let pic = mempics[y1];
                        if (pic.getInvolvedId == getInvolvedId) {
                            pic.totalLikes = totalLikes;
                            pic.canVote = false;
                            //this.msgBox('Likes updated to ' + totalLikes);
                            return;
                        }
                    }
                }
            },
            deletePic(e) {
                let getInvolvedId = e;
                for (let y = 0; y < this.picdata.length; y++) {
                    let mempics = this.picdata[y];
                    for (let y1 = 0; y1 < mempics.length; y1++) {
                        let pic = mempics[y1];
                        if (pic.getInvolvedId == getInvolvedId) {
                            mempics.splice(y1, 1);
                            if (mempics.length == 0) {
                                this.picdata.splice(y);
                            }
                            return;
                        }
                    }
                }
            },

        }

    }
</script>
