<template v-if="isLoggedIn">
    <div class="container page">

        <div class="card card-default" style="min-width:420px">
            <div class="card-header">
                <div class="row">
                    <div class="col-sm-6 font-weight-bold">
                        <h5>Management Statement</h5>
                    </div>

                    <div class="col-sm-3">

                    </div>
                    <div class="col-sm-3 text-right">
                        <b-button class="d-print-none mr-2" size="sm" @click.prevent="windowPrint" :disabled="report.length==0">Print</b-button>
                        <b-button class="d-print-none" size="sm" @click.prevent="exportToCSV" :disabled="report.length==0">Export to CSV</b-button>
                    </div>
                </div>

            </div>
            <div class="card-body">

                <table class="table table-hover table-condensed">
                    <tr><th>Date</th><th>Description</th><th class="text-right">Debit</th><th class="text-right">Credit</th></tr>
                    <tr v-for="f in report" :key="f._rowNum">
                        <td>{{ f.transDate.sjToDate('d MMM yyyy HH:mm') }}</td>
                        <td>{{ f.transDescription }}</td>
                        <td class="text-right">{{ ( 0 > f.pointsValue ) ? f.pointsValue : '' }}</td>
                        <td class="text-right">{{ f.pointsValue >= 0 ? f.pointsValue : '' }}</td>
                    </tr>
                </table>
                <div v-if="isLoading"><i>Loading...</i></div>


                <div><b><label ID="lblMessage" runat="server" Text="" ForeColor="#ff0000" /></b></div>
            </div>
        </div>



    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'


    export default {
		name: 'ManagementStatement',
        data() {
            return {
                monthList: [],
                selMonth: null,
                isAll: false,
                report: [],
                isLoading: false
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
        },

        created() {
            this.loadReport();
        },
        mounted() {
            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
		watch: {
			
		},

        methods: {
            loadReport() {
                this.isLoading = true;
                this.report = [];
                axios.post('/api/report/managementstatement', {  })
                    .then(({ data }) => {
						this.isLoading = false;

                        this.report = new sjData(data.report).data;
                    })
                    .catch((error) => {
						this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },


            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
			exportToCSV() {
				let fx = function (val, fn) {
					if (typeof fn === "undefined") fn = '';
					let isNum = (fn === 'num' || typeof val === "number") && !isNaN(val);
					try {
						if (isNum) {
							return val;
						} else {
							let s = val.sjReplaceAll("\r\n", " ~ ")
								.sjReplaceAll("\r", " ~ ")
								.sjReplaceAll("\n", " ~ ")
								.sjReplaceAll("\t", "   ")
								.trim();

							//ph - phone number - split after 3 chars
							if (fn == "ph" && s.length > 9) {
								if (s.substr(0, 1) === "0") {
									s = s.sjReplaceAll(' ', '').sjReplaceAll('-', '').sjReplaceAll('(', '').sjReplaceAll(')', '').substr(0, 3) + " " + s.substr(3, 3) + " " + s.substr(6, 4);
								} else {
									s = "'" + s;
								}
							}

							//https://blog.zsec.uk/csv-dangers-mitigations/
							if (s.indexOf("@") === 1 || s.indexOf("+") === 1 || s.indexOf("-") === 1 || s.indexOf("=") === 1 || s.indexOf("|") === 1 || s.indexOf("%") === 1) {
								s = "'" + s.sjReplaceAll("|", "\\|");
							}

							s = "\"" + s.sjReplaceAll("\"", "\"\"") + "\"";
							return s;
						}
					} catch {
						return 'Error';
					}
				}

				let csv = 'Date,Description,Debit,Credit\r\n';

				for (var n = 0; n < this.report.length; n++) {
					let f = this.report[n];
					//debugger;
					csv += fx(f.transDate.sjToDate('d MMM yyyy HH:mm')) + ","
						+ fx(f.transDescription) + ","
						+ (f.pointsValue < 0 ? -f.pointsValue : '') + ","
                        + (f.pointsValue >= 0 ? f.pointsValue : '') + "\r\n";
				}

				var pom = document.createElement('a');
				pom.href = URL.createObjectURL(new Blob(['\uFEFF', csv], { type: 'text/csv;charset=utf-8;' }));
				pom.setAttribute('download', 'report.csv');
				pom.click();
			},
			windowPrint() {
				window.print();
			},


        }

    }
</script>
