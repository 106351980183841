<template>
	<div v-if="status != ''">
		<h3>Logged In</h3>
		<p>Loading details - Status: {{ status }}</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isAuthenticating: false,
				status: ''
			}
		},
		methods: {

		},
		created() {
			this.isAuthenticating = true;
			this.status = "";
			this.$store
				.dispatch('memberStore/authsso', {
					//sitecode: from.params.siteCode,
					//ssokey: from.params.ssoKey
				})
				.then(() => {
					//console.log('   authSSO.vue - logged in success - $router.replace(');
					let cellNum = this.$store.state.memberStore.member.cellNum;
					if (cellNum == '') {
						this.$router.replace({ path: 'myprofile/update' }) //we use replace instead of push - to replace the current history entry
					} else {
						this.$router.replace({ name: 'Home' }) //we use replace instead of push - to replace the current history entry
					}
				})
				.catch(err => {
					let msg = err.response.data.message ? err.response.data.message : err.message;
					//console.log('   authSSO.vue - error logging in: ' + msg);
					this.status = 'Error occured: ' + msg;
				})
		},
		/*
				created() {
					if (this.$route.name === 'ssoAuthProcessing') {
						//this may be either if the person accesses this page directly, or more than likely the router has just been re-initialised due to parent changing.
						//just don't try anything - it aint gonna work! SJ (unless you store some global variable in the store)
						this.status = 'Loading...'
					} else {
						console.log('authSSO.vue - created(). Redirecting to ssoAuthProcessing component');
						this.$router.replace({ name: 'ssoAuthProcessing' });
					}
				},
				watch: {
					//Watch the route to see the route Name changes to 'ssoAuthProcessing'.
					//We will then call the authsso API
					$route(to, from) {
						console.log('   authSSO.vue - (url changed) : ' + to.name);
						if (to !== from && to.name === 'ssoAuthProcessing') {
							this.isAuthenticating = true;
							this.status = "Authenticating...";
							this.$store
								.dispatch('memberStore/authsso', {
									//sitecode: from.params.siteCode,
									//ssokey: from.params.ssoKey
								})
								.then(() => {
									console.log('   authSSO.vue - logged in success - $router.replace(');
									this.status = "Success";
									this.$router.replace({ name: 'Home' }) //we use replace instead of push - to replace the current history entry
								})
								.catch(err => {
									let msg = err.response.data.message ? err.response.data.message : err.message;
									console.log('   authSSO.vue - error logging in: ' + msg);
									this.status = 'Error occured: ' + msg;
								})
						}
					}
				}
				*/
	}
</script>
