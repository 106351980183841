 <template>
    <b-navbar toggleable="xl">
        <!--<b-navbar-brand href="#">
            <img alt="" src="../assets/image/topHeadLogoLeft.png" />
        </b-navbar-brand>-->

        <b-navbar-toggle class="ml-auto my-1" target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav v-if="isLoggedIn">
                <b-nav-item to="/"><b-icon icon="house-fill" aria-hidden="true"></b-icon></b-nav-item>
                <b-nav-item-dropdown text="My Profile" right>
                    <b-dropdown-item to="/myprofile">My Details</b-dropdown-item>
                    <b-dropdown-item to="/getinvolved">Get Involved</b-dropdown-item>
                    <!--<b-dropdown-item @click.prevent="$bvModal.show('modalHowItWorks')">How it Works</b-dropdown-item>-->
                    <b-dropdown-item @click.prevent="$bvModal.show('modalFAQ')">FAQs</b-dropdown-item>
                    <b-dropdown-item to="/contactus">Contact Us</b-dropdown-item>
                    <b-dropdown-header v-if="isManager || isAdminReports">Management</b-dropdown-header>
                    <b-dropdown-item v-if="isManager" to="/managementstatement">Management Statement</b-dropdown-item>
                    <b-dropdown-item v-if="isManager" to="/reportmanagement">Management Report</b-dropdown-item>
                    <b-dropdown-item v-if="isManager" to="/nominationmanagement">Management Nominations Review</b-dropdown-item>
                    <b-dropdown-item v-if="isAdminReports" to="/reportquarterlyawards">Quarterly Awards Report</b-dropdown-item>
                    <b-dropdown-item v-if="isAdminReports" to="/reportmanagerbalances">Manager Balances Report</b-dropdown-item>


                </b-nav-item-dropdown>

                <!--<b-nav-item to="/myprofile">My Profile</b-nav-item>
    <b-nav-item @click.prevent="$bvModal.show('modalHowItWorks')">How it Works</b-nav-item>
    <b-nav-item @click.prevent="$bvModal.show('modalFAQ')">FAQs</b-nav-item>-->

                <b-nav-item-dropdown text="My Rewards" right>
                    <b-dropdown-item to="/rewards">Redeem</b-dropdown-item>
                    <b-dropdown-item to="/rewards/basket">My Basket</b-dropdown-item>
                    <b-dropdown-item to="/orders">My Orders</b-dropdown-item>
                    <b-dropdown-item to="/statement">Statement</b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item to="/myrecognition">My Recognition</b-nav-item>
                <b-nav-item to="/rewardpayitforward">Pay It Forward</b-nav-item>

                <!--<b-nav-item-dropdown v-if="isManager" text="Management" right>
                    <b-dropdown-item v-if="isManager" to="/managementstatement">Management Statement</b-dropdown-item>
                    <b-dropdown-item v-if="isManager" to="/reportmanagement">Management Report</b-dropdown-item>
                    <b-dropdown-item v-if="isManager" to="/nominationmanagement">Management Nominations Review</b-dropdown-item>
                    <b-dropdown-item v-if="isAdminReports" to="/reportquarterlyawards">Quarterly Awards Report</b-dropdown-item>
                </b-nav-item-dropdown>-->

                <!--<b-nav-item to="/contactus">Contact Us</b-nav-item>-->
            </b-navbar-nav>

            <b-navbar-nav v-if="isLoggedIn" class="ml-auto">
                <b-nav-item to="/logout" class="navLogout">Log Out</b-nav-item>
            </b-navbar-nav>

            <b-navbar-nav v-if="!isLoggedIn" class="ml-auto">
                <!--<b-nav-item to="/">Home</b-nav-item>-->
                <!--<b-nav-item @click.prevent="$bvModal.show('modalHowItWorks')">How it Works</b-nav-item>
                <b-nav-item @click.prevent="$bvModal.show('modalFAQ')">FAQs</b-nav-item>-->
                <b-nav-item @click.prevent="$bvModal.show('modalLogin')" class="navLogout">Log In</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <!--
<b-navbar-nav class="ml-auto">
<b-nav-form>
    <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
    <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
</b-nav-form>


<b-nav-item-dropdown right>
    !-- Using 'button-content' slot --
    <template v-slot:button-content>
        <em>User</em>
    </template>
    <b-dropdown-item href="#">Profile</b-dropdown-item>
    <b-dropdown-item href="#">Sign Out</b-dropdown-item>
</b-nav-item-dropdown>
</b-navbar-nav>
-->
        </b-collapse>
    </b-navbar>
         
</template>

<script>
    export default {
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
			isManager() {
				return this.$store.state.memberStore.member.isManager
            },
			isAdminReports() {
				return this.$store.state.memberStore.member.isAdminReports
			},
			isClusterAdmin() {
				return this.$store.state.memberStore.member.role_ca
			},
			isClusterComm() {
				return this.$store.state.memberStore.member.role_cc
			}
        }
    }
</script>